<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<!-- eslint-disable @angular-eslint/template/prefer-control-flow -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<div class="upload-container">
  <!-- Drag and Drop Area -->
  <div
    class="drag-drop-area"
    [class.drag-over]="isDragOver"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <p i18n *ngIf="!uploadForm.get('resume')?.value">
      Drag & drop your resume here
    </p>
    <p *ngIf="uploadForm.get('resume')?.value">
      {{ uploadForm.get("resume")?.value.name }}
    </p>
  </div>

  <!-- Traditional File Input -->
  <input #fileInput type="file" hidden (change)="onFileChange($event)" />
  <button
    i18n
    type="button"
    mat-raised-button
    color="primary"
    (click)="fileInput.click()"
  >
    Select File
  </button>

  <!-- Upload Button -->
  <button i18n mat-raised-button color="primary" (click)="uploadResume()">
    Upload Resume
  </button>

  <!-- Upload Message -->
  <p>{{ uploadMessage }}</p>
</div>

<!-- Display resumes as cards -->
<div *ngIf="resumes.length > 0" class="resume-cards-container">
  <mat-card *ngFor="let resume of resumes" class="resume-card">
    <mat-card-title>{{ resume.fileName }}</mat-card-title>
    <mat-card-subtitle>
      {{ resume.uploadedAt | date: "medium" }}
    </mat-card-subtitle>

    <mat-card-actions>
      <!-- Analyse Button -->
      <button
        i18n
        mat-button
        color="primary"
        (click)="submitResumeForAnalysis(resume.id)"
      >
        Analyze
      </button>

      <!-- Download Button -->
      <button
        i18n
        mat-button
        color="accent"
        (click)="downloadResume(resume.fileUrl)"
      >
        Download
      </button>
    </mat-card-actions>
  </mat-card>
</div>
